
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { getRootUrl } from "@lib/auth/data/types";
import { useAuth, withRequireLogin } from "@lib/auth/ui";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
const HomePage = () => {
    const router = useRouter();
    const { user } = useAuth();
    const [cookie] = useCookies(["lang"]);
    useEffect(() => {
        const rootUrl = getRootUrl(user);
        if (rootUrl)
            router.replace(rootUrl, "", cookie.lang ? { locale: cookie.lang } : {});
    }, [router, user, cookie]);
    return null;
};
const __Next_Translate__Page__19503823ec0__ = withRequireLogin(HomePage);

    export default __appWithI18n(__Next_Translate__Page__19503823ec0__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  